import React, { useEffect, useRef, useState, type CSSProperties } from 'react'

import type { VideoProps } from '@/types/components/video'
import ResponsiveImage from './ResponsiveImage'

import './video.scss'

const Video: React.FC<VideoProps> = ({
  videoUrl,
  videoType,
  ratios,
  dialogueThumbnail,
  includeControls,
  loopVideo,
  captionsUrl,
  backupImage,
  classes,
  isFirstSection = false,
  buttonType = 'default',
  onPlayingChange,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const isBackgroundVideo = videoType === 'background'

  useEffect(() => {
    onPlayingChange?.(isPlaying)
  }, [isPlaying])

  const handlePlayPause = () => {
    if (!videoRef.current) return

    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }

    setIsPlaying(!isPlaying)
  }

  const source =
    !isBackgroundVideo && !dialogueThumbnail?.desktopImage?.asset.url
      ? `${videoUrl}#t=0.001` // Makes sure that the first frame of the video is shown as the poster
      : videoUrl
  const videoStyle: CSSProperties & { [key: string]: string } = {
    '--mobile-ratio': ratios?.mobileRatio?.videoRatio || 'auto',
    '--desktop-ratio': ratios?.desktopRatio?.videoRatio || 'auto',
  }

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        crossOrigin={`${!isBackgroundVideo && captionsUrl ? 'anonymous' : ''}`}
        autoPlay={isBackgroundVideo}
        muted={isBackgroundVideo}
        loop={isBackgroundVideo && loopVideo}
        controls={isPlaying && !isBackgroundVideo && includeControls}
        className={classes}
        playsInline
        poster={
          !isBackgroundVideo ? dialogueThumbnail?.desktopImage?.asset.url : ''
        }
        style={videoStyle}
      >
        <source src={source} />
        {!isBackgroundVideo ? (
          <track kind="captions" src={captionsUrl} srcLang="en" default />
        ) : null}
        <ResponsiveImage
          document={backupImage}
          overrides={{
            loading: isFirstSection ? 'eager' : 'lazy',
            customSizes: [640, 768, 1024, 1200],
          }}
        />
      </video>
      {!isBackgroundVideo &&
        !isPlaying &&
        (buttonType === 'primary' ? (
          <button
            onClick={handlePlayPause}
            className="video-play-button button button--primaryLime button--small opacity-100"
          >
            Play ▶
          </button>
        ) : (
          <button onClick={handlePlayPause} className="video-play-button">
            <div className="triangle-right"></div>
          </button>
        ))}
    </div>
  )
}

export default Video
